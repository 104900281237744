import AuthActions from '../../actions/auth/authActions.enum';
import ReduxAction from '../../actions/reduxAction.interface';
import AuthState from './authState.interface';

const initialState: AuthState = {
  accessToken: '',
};

export default function authReducer(
  state = initialState,
  action: ReduxAction<AuthActions>,
) {
  switch (action.type) {
    case AuthActions.AUTH_SAVE_ACCESS_TOKEN:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
