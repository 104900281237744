import React, { useState } from 'react';
import Footer from '../../pages/Layout/Footer';
import Header from '../../pages/Layout/Header';
import './signup.css';
import Logo from '../../assets/LogoSingle.svg';
import * as Yup from 'yup';
import PasswordInput from '../../components/PasswordInput';
import TextInput from '../../components/TextInput';
import history from '../../constants/history';
import { toast } from 'react-toastify';
import api from '../../api';
import SignUpRequestData from '../../api/endpoints/auth/interfaces/signUpRequestData.interface';
export default () => {
  const emailFormValidationSchema = Yup.object().shape({
    Email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    Password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required'),
  });

  const [login, setLogin] = useState<any>()
  const [pwd, setPwd] = useState<any>()
  const submitData = (data:SignUpRequestData) =>{
    api.auth.signUp(data)
  }
  return (
    <div className="login_bg ">
      <Header />
      <div className="content_signup">
        <img src={Logo} alt="Logo" />
        <p className="text-block_login">
          Please, Sign Up to have the access to the system
        </p>
        <form onSubmit={()=>submitData({...login,...pwd})}>
          <div className="mb-3">
            <TextInput
              onChange={setLogin}
              className="input_login"
              placeholder="john.doe@address.com"
              labelText="Phone or Email*"
              labelStyle="text-block_login_input form-label float-start"
            />
          </div>
          <div className="mb-3">
            <PasswordInput
            onChange={setPwd}
              className="input_login"
              labelText="Password*"
              labelStyle="text-block_login_input form-label float-start"
            />
          </div>
          <button type="submit" className="btn btn-light button_login_submit">
            Sign Up
          </button>
          <ul className="list-inline">
            <li className="text-block_login_bottom_text float-start mt-3">
              Dont have an account?&nbsp;
            </li>
            <li className="text-block_login_bottom_text float-start mt-3 underscored">
              <span
                onClick={() => {
                  history.push('/login');
                }}
              >
                Log in
              </span>
            </li>
          </ul>
        </form>
        <div className="form-check pt-4">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label className="form-check-label text-block_login_bottom_text" htmlFor="flexCheckDefault">
            I agree to the Terms of Services and Privacy Policy
            </label>
          </div>
      </div>
      <Footer isLogin isSocial />
    </div>
  );
};
