import React, { FC, HTMLInputTypeAttribute, memo } from 'react';
import clsx from 'clsx';
import uploadButton from '../../assets/uploadButton.svg'
import styles from './uploadImage.module.css'
type Props = {
handleClick: () => void;
tiles?:Array<string>;
};

const TextInput: FC<Props> = ({
handleClick,
tiles = ['input1', 'input2', 'input3', 'input4', 'input5', 'input6']
}) => {
  return (
<>
{tiles.map((e)=>{
  return(
 <>
  <label htmlFor={e}>
    <img className='p-2' src={uploadButton}/>
  </label>

  <input className={styles.hide} id={e} type="file" />
</>
  )
})}
</>
  );
};

export default memo(TextInput);
