import clsx from 'clsx';
import React from 'react';
import './index.css';
import './main.css';

interface onboardingProps {
  screen?: any;
  children?: any;
  className?: any;
}

export default ({ screen, children, className }: onboardingProps) => {
  const { title, description, textLeft, textRight, img } = screen;

  return (
    <div className="main">
      <div
        id="OnboardingPage"
        className="content d-flex justify-content-between"
      >
        {textLeft && (
          <div className="text-block left">
            <h1 className="title">{title}</h1>
            <p>{description}</p>
            {children}
          </div>
        )}
        <div
          className={clsx(
            'img',
            textLeft ? 'leftPosition' : 'rightPosition',
            className,
          )}
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/svg/${img})`,
          }}
        />
        {textRight && (
          <div className="text-block right">
            <h1 className="title">{title}</h1>
            <p>{description}</p>
            {children}
          </div>
        )}
      </div>
    </div>
  );
};
