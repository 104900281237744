import React from 'react';
import Footer from '../../pages/Layout/Footer';
import Header from '../../pages/Layout/Header';
import './login.css';
import Logo from '../../assets/LogoSingle.svg';
import * as Yup from 'yup';
import PasswordInput from '../../components/PasswordInput';
import TextInput from '../../components/TextInput';
export default () => {
  const emailFormValidationSchema = Yup.object().shape({
    Email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    Password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required'),
  });

  return (
    <div className="login_bg ">
      <Header />
      <div className="content_login">
        <img src={Logo} alt="Logo" />
        <p className="text-block_login">
          Please, choose new password
        </p>
        <form>
          <div className="mb-3">
            <TextInput
              className="input_login"
              placeholder="john.doe@address.com"
              labelText="Phone or Email*"
              labelStyle="text-block_login_input form-label float-start"
            />
          </div>
          <div className="mb-3">
            <PasswordInput
              className="input_login"
              labelText="Password*"
              labelStyle="text-block_login_input form-label float-start"
            />
          </div>
          <div className="mb-3">
            <PasswordInput
              className="input_login"
              labelText="Confirm Password*"
              labelStyle="text-block_login_input form-label float-start"
            />
          </div>
          <button type="submit" className="btn btn-light button_login_submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};
