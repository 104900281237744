import React from 'react';

import Logo from '../../../assets/logo.svg';
import Avatar from '../../../assets/avatar.svg';
import history from '../../../constants/history';
import './header.css';

interface headerProps {
  isLogin?: boolean;
  isSignUp?: boolean;
}

export default ({ isLogin, isSignUp }: headerProps) => (
  <div className="header d-flex justify-content-between">
    <img
      onClick={() => {
        history.push('/');
      }}
      className="logo_pointer"
      src={Logo}
      alt="Logo"
    />
    <div className="d-flex">
      {isLogin ? (
        <button
          type="button"
          onClick={() => {
            history.push('/login');
          }}
          className="button border-0"
        >
          Log In
        </button>
      ) : null}
      {isSignUp ? (
        <button
          type="button"
          onClick={() => {
            history.push('/signup');
          }}
          className="button border-0"
        >
          Sign up
        </button>
      ) : null}
      <img className="avatar" src={Avatar} alt="Avatar" />
    </div>
  </div>
);
