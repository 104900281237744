import SignInRequestData from '../../../api/endpoints/auth/interfaces/signInRequestData.interface';
import SaveAccessTokenAction from './interfaces/saveAccessTokenAction.interface';
import AuthActions from './authActions.enum';
import SignInAction from './interfaces/signInAction.interface';

export const saveAccessToken = (
  payload: SaveAccessTokenAction['payload'],
): SaveAccessTokenAction => ({
  type: AuthActions.AUTH_SAVE_ACCESS_TOKEN,
  payload,
});

export const signIn = (payload: SignInRequestData): SignInAction => ({
  type: AuthActions.AUTH_SIGN_IN_REQUEST,
  payload,
});
