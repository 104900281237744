import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import OnboardScreen from '../components/OnboardScreen';
import AppRoutes from '../constants/appRoutes.enum';
import history from '../constants/history';
import Login from '../pages/Login';
import ForgotPwd from './ForgotPwd';
import Registration from './Registration';
import Signup from './Signup';
import SignupCode from './SignupCode';
import SignupImage from './SignupImage';

export default () => (
  <Router history={history}>
    <Switch>
      <Route exact path={AppRoutes.Home} component={OnboardScreen} />
      <Route exact path={AppRoutes.LogIn} component={Login} />
      <Route exact path={AppRoutes.Signup} component={Signup} />
      <Route exact path={AppRoutes.ForgotPwd} component={ForgotPwd} />
      <Route exact path={AppRoutes.SignupCode} component={SignupCode} />
      <Route exact path={AppRoutes.CompleteReg} component={Registration} />
      <Route exact path={AppRoutes.SignupImage} component={SignupImage} />
    </Switch>
  </Router>
);
