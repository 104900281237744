import clsx from 'clsx';
import React, { memo } from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap-v5';
import { ReactComponent as Close } from '../../assets/close.svg';
import styles from './modal.module.scss';

interface ModalProps {
  show: boolean;
  title?: string;
  onHide?: () => void;
  onSubmit?: () => void;
  children: React.ReactNode;
  footerContent?: React.ReactNode;
  widthDefaultSize?: boolean;
  withFooter?: boolean;
  withHeader?: boolean;
  bodyClassName?: string;
  dialogClassName?: string;
  submitButtonText?: string;
  withoutSubmitButton?: boolean;
}

const Modal = ({
  show,
  onHide,
  onSubmit,
  title = '',
  children,
  footerContent,
  withFooter = true,
  withHeader = true,
  widthDefaultSize = true,
  bodyClassName,
  dialogClassName,
  submitButtonText = '',
  withoutSubmitButton = false,
}: ModalProps) => (
  <>
    <BootstrapModal
      enforceFocus={false}
      show={show}
      onHide={onHide}
      dialogClassName={clsx(
        'modal-dialog-centered mx-auto px-2',
        widthDefaultSize && 'mw-300px',
        dialogClassName,
      )}
      contentClassName={clsx(styles.contentClassName, 'px-4 pb-4')}
    >
      {withHeader && (
        <div className="modal-header px-0">
          <h5 className="w-100 text-center mb-0 fw-bold">{title}</h5>
          <div
            role="button"
            tabIndex={-1}
            onKeyPress={onHide}
            className="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            onClick={onHide}
          >
            <Close />
          </div>
        </div>
      )}
      <div className={clsx('modal-body pb-2', bodyClassName)}>{children}</div>
      {withFooter && (
        <div className="modal-footer border-top-0 pb-4 pt-0">
          {footerContent || (
            <>
              {!withoutSubmitButton && (
                <button
                  id="submit"
                  type="button"
                  className={`btn w-100 text-white py-2 rounded ${styles.primaryBtn}`}
                  onClick={onSubmit}
                >
                  {submitButtonText}
                </button>
              )}
            </>
          )}
        </div>
      )}
    </BootstrapModal>
  </>
);

Modal.Separator = memo(
  ({
    className,
    withoutDefaultMargins,
  }: {
    className?: string;
    withoutDefaultMargins?: boolean;
  }) => (
    <div
      className={clsx(
        'd-flex position-relative',
        !withoutDefaultMargins && 'mt-8 mb-7',
        className,
      )}
    >
      <div className={clsx('separator position-absolute', styles.separator)} />
    </div>
  ),
);

export default Modal;
