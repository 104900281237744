enum AppRoutes {
  Home = '/',
  LogIn = '/login',
  NotFound = '/404',
  Signup = '/signup',
  ForgotPwd = '/forgotpwd',
  SignupCode = '/signupcode',
  CompleteReg = '/registration',
  SignupImage = '/signupimage',
}

export default AppRoutes;
