import axios from 'axios';
import AuthRoutes from './authRoutes.enum';
import { baza, REACT_APP_API_URL } from '../../../constants/api';
import SignInRequestData from './interfaces/signInRequestData.interface';
import SignInResponseData from './interfaces/signInResponseData.interface';
import SignUpRequestData from './interfaces/signUpRequestData.interface';

export default {
  /**
   * Performs the sign in request
   * @param requestData
   */
  signIn: async (
    requestData: SignInRequestData,
  ): Promise<any> => {
    const { data } = await axios({
      method: 'post',
      url: `http://glacial-castle-15169.herokuapp.com/api/${AuthRoutes.SignIn}`,
      data: requestData,
    });

    return data;
  },

  signUp: async (requestData: SignUpRequestData): Promise<any> => {
    const { data } = await axios({
      method: 'post',
      url: `${baza}/${AuthRoutes.SignUp}`,
      data: requestData,
    });
    return data;
  },
};
