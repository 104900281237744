export const onboardMock = [
  {
    id: 1,
    title: 'Easy & Fun Search',
    description:
      'Browse dental offices, potential employees, and connect with friends.',
    img: 'iphones.svg',
    textLeft: true,
  },
  {
    id: 2,
    title: 'The Resume Reimagined',
    description:
      'We include all the traditional content plus much more, like pictures and endorsements from past employers and coworkers.',
    img: 'laptop.svg',
    textRight: true,
  },
  {
    id: 3,
    title: 'Customize Your Search',
    description:
      "Search for the perfect job and employee with custom filters to focus in on exactly what you're looking for.",
    img: 'macbook-iphone.svg',
    textLeft: true,
  },
  {
    id: 4,
    title: 'Build Your Stacks',
    description:
      'Browse dental offices, potential employess, and connect with friends.',
    img: 'macbook.svg',
    textRight: true,
  },
];
