import React, { useEffect, useState } from 'react';
import Footer from '../../pages/Layout/Footer';
import Header from '../../pages/Layout/Header';
import styles from './registration.module.css';
//import './registration.module.css';
import Logo from '../../assets/LogoSingle.svg';
import * as Yup from 'yup';
import PasswordInput from '../../components/PasswordInput';
import TextInput from '../../components/TextInput';
import clsx from 'clsx';
export default () => {
  const [flex, setFlex] = useState<boolean | null>(
    window.matchMedia('(min-width: 1530px)').matches,
  );

  useEffect(() => {
    const handler = (e: { matches: boolean }) => setFlex(e.matches);
    window
      .matchMedia('(min-width: 1530px)')
      .addEventListener('change', handler);
  }, []);

  const emailFormValidationSchema = Yup.object().shape({
    Email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    Password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required'),
  });
  return (
    <div className="login_bg ">
      <Header />
      <div className={styles.content_login}>
        <img src={Logo} alt="Logo" />
        <p className="text-block_login">Please, complete Registration</p>
        <form>
          <div
            className={flex ? 'd-flex justify-content-evenly mb-3' : 'mb-3'}
          >
            <TextInput
              withColumn={flex ? false : true}
              className={flex ? styles.input_login : styles.input_login_query}
              placeholder="John"
              labelText="First Name*"
              labelStyle="text-block_login_input form-label float-start"
            />
            <TextInput
            withColumn={flex ? false : true}
              className={flex ? styles.input_login : styles.input_login_query}
              placeholder="Dou"
              labelText="Last Name*"
              labelStyle="text-block_login_input form-label float-start"
            />
          </div>
          <div
            className={flex ? 'd-flex justify-content-evenly mb-3' : 'mb-3'}
          >
            <TextInput
              withColumn={flex ? false : true}
              className={flex ? styles.input_login : styles.input_login_query}
              placeholder="Dental Assitant"
              labelText="Your Dental Speciality*"
              labelStyle="text-block_login_input form-label float-start"
            />
            <TextInput
            withColumn={flex ? false : true}
              className={flex ? styles.input_login : styles.input_login_query}
              placeholder="Type here"
              labelText="Years of Experience*"
              labelStyle="text-block_login_input form-label float-start"
            />
          </div>

            <TextInput
              withColumn={flex ? false : true}
              className={flex ? clsx(styles.input_login, 'mb-3') : styles.input_login_query}
              placeholder="Select Your Location"
              labelText="Location*"
              labelStyle="text-block_login_input form-label float-start"
            />
          <button type="submit" className="btn btn-light button_login_submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};
