import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import clsx from 'clsx';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Slider from 'react-slick';
import { toast } from 'react-toastify';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import arrowImage from '../../assets/arrow.svg';
import firstScreen from '../../assets/images/default/first_screen.png';
import Logo from '../../assets/logo.svg';
import { onboardMock } from '../../constants/onboardMock';
import OnboardContainer from '../../containers/OnboardContainer';
import Footer from '../../pages/Layout/Footer';
import { prepareHookFromState } from '../../utils/hooks/useStateWithHookForm';
import Yup from '../../utils/validation';
import FormErrorMessage from '../FormErrorMessage';
import Modal from '../Modal';
import TextInput from '../TextInput';
import styles from './index.module.css';
import {
  doc,
  client_email,
  private_key,
} from '../../utils/google-spreadsheet-credentials';

interface slider extends Slider {
  slickNext: () => void;
  slickPrev: () => void;
}

interface FormInputs {
  name: string;
  email: string;
}
export default () => {
  const [notifiedMembers, setNotifiedMembers] = useState<
    { name: string; email: string }[]
  >([]);

  const slider = useRef<slider>(null);

  const next = (index: number) => {
    if (index === onboardMock.length - 1) {
      window.scrollTo({ top: 0 });
    } else {
      slider.current?.slickNext();
    }
  };
  const prev = () => slider.current?.slickPrev();

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
  };
  const [notifyModalOpen, setNotifyModalOpen] = useState(false);

  const defaultValues: Partial<FormInputs> = {
    name: '',
    email: '',
  };
  const {
    setValue,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm<Partial<FormInputs>>({
    resolver: yupResolver(
      Yup.object({
        name: Yup.string().required('Field is required'),
        email: Yup.string().email().required('Field is required'),
      }).required(),
    ),
    defaultValues,
  });

  const useFs = prepareHookFromState<Partial<FormInputs>>(
    setValue,
    trigger,
    defaultValues,
  );

  const getNotifiedMembers = async () => {
    try {
      await doc.useServiceAccountAuth({
        client_email,
        private_key,
      });
      await doc.loadInfo();

      const sheet = doc.sheetsById[0];
      const data = (await sheet.getRows()).map((eachE) =>
        _.pick(eachE, ['email', 'name']),
      ); // take only email and name fields from the response
      setNotifiedMembers(data);
    } catch (e) {
      console.error('Error: ', e);
    }
  };

  useEffect(() => {
    getNotifiedMembers();
  }, []);

  useEffect(() => {
    getNotifiedMembers();
  }, [notifyModalOpen]);

  const [name, setName] = useFs<FormInputs['name']>('name');
  const [email, setEmail] = useFs<FormInputs['email']>('email');

  const handleSubmitData = async () => {
    const notifyCreds = { name: name.trim(), email: email.trim() };

    let isNotified = _.find(notifiedMembers, (notifiedMember) =>
      _.isEqual(notifyCreds, notifiedMember),
    );

    if (isNotified) {
      toast.error("You are already on notified people's list");
    } else {
      try {
        await doc.useServiceAccountAuth({
          client_email,
          private_key,
        });
        await doc.loadInfo();

        const sheet = doc.sheetsById[0];
        sheet.addRow({ email, name });
      } catch (e) {
        console.error('Error: ', e);
      }
      toast.success('You will be notified of Early Release');
      setNotifyModalOpen(false);
      setName('');
      setEmail('');
    }
  };

  return (
    <>
      <Modal
        dialogClassName={styles.dialogClassName}
        title="Notify of Early Release"
        submitButtonText="Notify"
        show={notifyModalOpen}
        onHide={() => setNotifyModalOpen(false)}
        onSubmit={handleSubmit(handleSubmitData)}
      >
        <TextInput
          name="name"
          id="name"
          labelText="Name"
          placeholder="Type here"
          onChange={setName}
          value={name}
          required
        />
        <FormErrorMessage
          name="name"
          errors={errors}
          className="mt-1 min-h-20px"
        />
        <TextInput
          name="email"
          id="email"
          labelText="Email"
          placeholder="example@mail.com"
          type="email"
          onChange={setEmail}
          value={email}
          required
        />
        <FormErrorMessage
          name="email"
          errors={errors}
          className="mt-1 min-h-20px"
        />
      </Modal>

      <div className={styles.container}>
        <img src={Logo} className={styles.mobileLogo} alt="Logo" />
        <div
          style={{
            background: `url("${firstScreen}") no-repeat center top`,
            backgroundSize: 'cover',
            minWidth: '400px',
          }}
          className={clsx(styles.imageContainer)}
        >
          <img src={Logo} alt="Logo" />
        </div>
        <div
          style={{ maxWidth: 'unset' }}
          className={`container ${styles.rightContainer}`}
        >
          <div className={`${styles.textBlock} h-100 py-8`}>
            <h1 className="title text-center">One Dental Match</h1>
            <p className="text-center">
              Welcome to the most modern, fun and beautiful digital staffing
              platform that's connecting the Dental World
            </p>
            <button
              onClick={() => setNotifyModalOpen(true)}
              className={`btn w-100 text-white py-3 rounded fw-bold ${styles.gradientBtn}`}
            >
              Notify me of Early Release!
            </button>
            <h2 className="title fs-3 mt-4 text-center">Coming Spring 2022</h2>
          </div>
        </div>
      </div>
      <div className={styles.onboardingLink}>
        <a href="#OnboardingPage">
          <img src={arrowImage} />
          <p className="text-center">
            Click to <br />
            Scroll Down
          </p>
        </a>
      </div>
      <Slider ref={slider} {...settings}>
        {onboardMock.map((screen, index) => (
          <OnboardContainer key={screen.id} screen={screen}>
            <div className="d-flex">
              <button className="navigate-button skip" onClick={prev}>
                Prev
              </button>
              <button
                className="border-0 navigate-button next"
                onClick={() => next(index)}
              >
                Next
              </button>
            </div>
          </OnboardContainer>
        ))}
      </Slider>
      <Footer />
    </>
  );
};
