import React, { useState } from 'react';
import Footer from '../../pages/Layout/Footer';
import Header from '../../pages/Layout/Header';
import './login.css';
import Logo from '../../assets/LogoSingle.svg';
import * as Yup from 'yup';
import PasswordInput from '../../components/PasswordInput';
import TextInput from '../../components/TextInput';
import history from '../../constants/history';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import validation from '../../utils/validation';
import { prepareHookFromState } from '../../utils/hooks/useStateWithHookForm';
import api from '../../api';
interface FormInputs {
  login?: string;
  password?: string;
}

export default () => {
  const defaultValues: Partial<FormInputs> = {
    login: '',
    password: '',
  };

  const { handleSubmit, setValue, trigger } = useForm<Partial<FormInputs>>({
    resolver: yupResolver(
      validation
        .object({
          login: validation.string().required('enter login'),
          password: validation.string().required('enter password'),
        })
        .required(),
    ),
    defaultValues,
  });
  const useFs = prepareHookFromState<Partial<FormInputs>>(
    setValue,
    trigger,
    defaultValues,
  );

  const [login, setLogin] = useFs<FormInputs['login']>('login');
  const [password, setPassword] = useFs<FormInputs['password']>('password');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitData = (inputsData: FormInputs) => {
    setIsLoading(true);
    api.auth.signIn(inputsData)
    .finally(() => setIsLoading(false));
    toast.success('message')
  };

  return (
    <div className="login_bg ">
      <Header />
      <div className="content_login">
        <img src={Logo} alt="Logo" />
        <p className="text-block_login">
          Please, Log In to have the access to the system
        </p>
        <form onSubmit={handleSubmit(handleSubmitData)}>
          <div className="mb-3">
            <TextInput
              value={login}
              onChange={setLogin}
              className="input_login"
              placeholder="john.doe@address.com"
              labelText="Phone or Email*"
              labelStyle="text-block_login_input form-label float-start"
            />
          </div>
          <div className="mb-3">
            <PasswordInput
              value={password}
              onChange={setPassword}
              className="input_login"
              labelText="Password*"
              labelStyle="text-block_login_input form-label float-start"
            />
          </div>
          <button type="submit" className="btn btn-light button_login_submit">
            Log in
          </button>
          <ul className="list-inline">
            <li className="text-block_login_bottom_text float-start mt-3">
              Dont have an account?&nbsp;
            </li>
            <li className="text-block_login_bottom_text float-start mt-3 underscored">
              <span
                onClick={() => {
                  history.push('/signup');
                }}
              >
                Sign up
              </span>
            </li>
            <li className="text-block_login_bottom_text float-end mt-3 underscored">
              <span
                onClick={() => {
                  history.push('/forgotpwd');
                }}
              >
                Forgot password
              </span>
            </li>
          </ul>
        </form>
      </div>
      <Footer isLogin isSocial />
    </div>
  );
};
