import React from 'react';
import Footer from '../../pages/Layout/Footer';
import Header from '../../pages/Layout/Header';
import styles from './image.module.css';
import Logo from '../../assets/LogoSingle.svg';
import * as Yup from 'yup';
import PasswordInput from '../../components/PasswordInput';
import TextInput from '../../components/TextInput';
import history from '../../constants/history';
import UploadImage from '../../components/UploadImage';
export default () => {
  const emailFormValidationSchema = Yup.object().shape({
    Email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    Password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required'),
  });

  const onUpload = () =>{
    console.log('uploaded')
  }

  return (
    <div className="login_bg ">
      <Header />
      <div className={styles.content_login}>
        <img src={Logo} alt="Logo" />
        <p className="text-block_login">
          Pick the perfect profile pic and others that tell your offices story
        </p>
        <form>
          <UploadImage handleClick={onUpload} />
          <div className='pt-3'>
          <button type="submit" className="btn btn-light button_login_submit">
            Submit
          </button>
          </div>
        </form>
      </div>
    </div>
  );
};
