import validator from 'validator';
import * as Yup from 'yup';
import { AnyObject, Maybe } from 'yup/lib/types';

Yup.addMethod(Yup.string, 'isEmail', function validateEmail(message: string) {
  return this.test(function isEmail(value) {
    const { path, createError } = this;

    if (typeof value !== 'string' || !validator.isEmail(value)) {
      return createError({ path, message: message ?? 'Invalid!' });
    }

    return true;
  });
});

Yup.addMethod(
  Yup.string,
  'isMobilePhone',
  function validatePhoneNumber(message: string) {
    return this.test(function isMobilePhone(value) {
      const { path, createError } = this;

      if (typeof value !== 'string' || !validator.isMobilePhone(value)) {
        return createError({ path, message: message ?? 'Invalid!' });
      }

      return true;
    });
  },
);

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType,
  > extends Yup.BaseSchema<TType, TContext, TOut> {
    isEmail(message: string): StringSchema<TType, TContext>;
    isMobilePhone(message: string): StringSchema<TType, TContext>;
  }
}

export default Yup;
