import axios from 'axios';
import { toast } from 'react-toastify';
import errorsCodesMessages from '../../constants/api/errorsCodesMessages';

const ignoredStatusCodes = [401];
const networkErrorMessage = 'Network Error';

/**
 * Intercepts server error responses and shows error messages
 */
export default () => {
  axios.interceptors.response.use(undefined, async (error) => {
    const { response } = error;

    if (response) {
      if (response.config.disableToast) {
        return {};
      }

      const {
        data: { message, statusCode },
      }: { data: { message: string; statusCode: number } } = response;
      if (
        message &&
        (!statusCode || !ignoredStatusCodes.includes(statusCode))
      ) {
        const errorCodeMessage = (
          errorsCodesMessages as { [key: string]: string }
        )[statusCode];

        toast.error(
          `${errorCodeMessage ? `${errorCodeMessage}: ` : ''}${message}`,
        );
      }
    } else if (error.message === networkErrorMessage) {
      toast.error(networkErrorMessage);
    }

    return Promise.reject(error);
  });
};
