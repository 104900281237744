import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../../api';
import SignInResponseData from '../../api/endpoints/auth/interfaces/signInResponseData.interface';
import { saveAccessToken } from '../actions/auth';
import AuthActions from '../actions/auth/authActions.enum';
import SignInAction from '../actions/auth/interfaces/signInAction.interface';

function* signInRequestWorker({ payload }: SignInAction) {
  try {
    const responseData: SignInResponseData = yield call(
      api.auth.signIn,
      payload,
    );

    yield put(saveAccessToken(responseData));
  } catch (error) {
    console.log(error);
  }
}

export default function* authWatcher() {
  yield takeEvery<SignInAction>(
    AuthActions.AUTH_SIGN_IN_REQUEST,
    signInRequestWorker,
  );
}
