import React from 'react';
import Facebook from '../../../assets/Facebook.svg'
import Linkedin from '../../../assets/Linkedin.svg'
import Google from '../../../assets/Google.svg'
import AppleID from '../../../assets/AppleID.svg'
import './footer.css';

interface footerProps {
  isLogin?: boolean;
  isSocial?: boolean;
}

export default ({ isLogin, isSocial }: footerProps) => {
  return (
    <>
      {isSocial ? (
        <div className="footer d-flex justify-content-between">
          <div className="socials">
            {isLogin ? <p className="text">or log in with</p> : null}
            <img src={Facebook} alt="Avataaaaaar" />
            <img src={Linkedin} alt="Avataaaaaar" />
            <img
              src={Google}
              alt="Avataaaaaar"
            />{' '}
            <img src={AppleID} alt="Avataaaaaar" />
          </div>
        </div>
      ) : (
        <p
          style={{ backgroundColor: '#4FC1C1' }}
          className="text-center py-4 my-0 text-white px-1"
        >
          All rights reserved. One Dental Match 2022
        </p>
      )}
    </>
  );
};
