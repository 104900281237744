import { ErrorMessage } from '@hookform/error-message';
import React from 'react';

export default ({
  name,
  errors,
  className,
}: {
  name: string;
  errors: any;
  className?: string;
}) => (
  <div className={className}>
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => <p style={{ color: 'red' }}>{message}</p>}
    />
  </div>
);
