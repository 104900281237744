import React, { FC, HTMLInputTypeAttribute, memo } from 'react';
import clsx from 'clsx';

type Props = {
  id?: string;
  value?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  placeholder?: string;
  labelText?: string;
  className?: string;
  useDisabledStyles?: boolean;
  name?: string;
  required?: boolean;
  type?: HTMLInputTypeAttribute;
  labelStyle?: string;
  withColumn?: boolean;
};

const TextInput: FC<Props> = ({
  id,
  value,
  disabled = false,
  onChange,
  placeholder = '',
  className,
  useDisabledStyles = false,
  name,
  labelText,
  type = 'text',
  labelStyle = '',
  required,
  withColumn = false,
}) => {
  return (
    <div className={clsx(withColumn ? 'd-flex flex-column' : '', 'mb-2')}>
      <label
        htmlFor={name}
        className={clsx('fs-8 mb-1', labelStyle, required && 'required')}
      >
        {labelText}
      </label>
      <div>
        <input
          className={clsx(
            !disabled && !useDisabledStyles,
            'form-control form-control-solid',
            className,
          )}
          id={id}
          name={name}
          disabled={disabled}
          type={type}
          value={value}
          onChange={({ currentTarget: { value: targetValue } }) => {
            if (onChange) onChange(targetValue);
          }}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default memo(TextInput);
